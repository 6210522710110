export type Settings = {
    data_dir: string
    session_age_seconds: number
    api_address: string
    api_port: number
    api_port_proxy: number
    api_protocol: string
    index_address: string
    index_port: number
    web_address: string
    crates_io_proxy: boolean
    log_level: string
    license: string
}

export const defaultSettings = {
    data_dir: "",
    session_age_seconds: 0,
    api_address: "",
    api_port: 0,
    api_port_proxy: 0,
    api_protocol: "",
    index_address: "",
    index_port: 0,
    web_address: "",
    crates_io_proxy: false,
    log_level: "",
    license: ""
}
