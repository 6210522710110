<template>
  <h1 class="title">Publish Crate Documentation</h1>

  <p>
    <i>Kellnr</i> provides an easy option to host the corresponding
    <a href="https://doc.rust-lang.org/rustdoc/index.html">Rustdoc</a>
    documentation for your crates. After annotating your project with
    <a href="https://doc.rust-lang.org/rustdoc/index.html">Rustdoc</a>, the docs
    need to be zipped and uploaded to <i>Kellnr</i>.
  </p>

  <pre v-highlightjs>
    <code class="bash">
# Generate documentation for the project
cargo doc

# Package documentation for the upload
cd ./target
zip -r doc.zip ./doc

# Upload documentation to Kellnr --> Replace values in brackets
curl -H "Authorization: {authorization token}" http{s}://{Kellnr host}/api/v1/docs/{crate name}/{crate version} --upload-file {docs archive}
    </code>
  </pre>

  <p>
    To upload the documentation, <i>Kellnr</i> checks that a <i>crate</i> with a
    corresponding version exists and that the user has the right to upload the
    documentation. If no <i>crate</i> with the correct version exists, the
    upload will fail. The authorization mechanism for uploading documentation is
    the same like it is used for <i>crates</i>. The user needs an authentication
    token that is associated with an owner of the package or with an admin user
    to be able to upload the documentation.
  </p>

  <h2 class="title is-4">Example</h2>

  <pre v-highlightjs>
    <code class="bash">
# Example: Upload documentation to Kellnr
curl -H "Authorization: Xjd83hDh45FLJ58bdShd4uhVNdnded4f" http://kellnr.example.com:8000/api/v1/docs/mycrate/1.0.0 --upload-file doc.zip
    </code>
  </pre>
</template>

<script setup lang="ts">
</script>

<style scoped>
body[color-theme="light"] pre {
  background-color: white;
  padding: 0;
}

body[color-theme="dark"] pre {
  background-color: var(--dark-bg-body);
  padding: 0;
}

h2 {
  padding-top: 1em;
}
</style>
