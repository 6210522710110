
import StatusNotification from "../components/StatusNotification.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "UserMgmt",
  components: {
    StatusNotification,
  },
  data() {
    return {
      addUserStatus: "",
      addUserMsg: "",
      changeUserStatus: "",
      changeUserMsg: "",
      items: [],
      name: "",
      pwd1: "",
      pwd2: "",
      is_admin: false
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    addUser() {
      const BASE_URL = process.env.VUE_APP_BASEURL;
      const postData = {
        name: this.name,
        pwd1: this.pwd1,
        pwd2: this.pwd2,
        is_admin: this.is_admin,
      };

      this.axios
          .post(BASE_URL + "/user/add", postData)
          .then((res) => {
            if (res.status == 200) {
              this.addUserStatus = "Success";
              this.addUserMsg = "User successfully added.";
              // Update user list
              this.getUsers();
            }
          })
          .catch((error) => {
            if (error.response) {
              this.addUserStatus = "Error";
              this.addUserMsg = "User could not be added.";

              if (error.response.status == 404) {
                // "Unauthorized. Login first."
                this.$router.push("/login");
              } else if (error.response.status == 400) {
                this.addUserMsg = "Password do not match";
              } else if (error.response.status == 500) {
                this.addUserMsg = "User could not be added";
              } else {
                this.addUserMsg = "Unknown error";
              }
            }
          });
    },
    getUsers() {
      const BASE_URL = process.env.VUE_APP_BASEURL;
      this.axios
          .get(BASE_URL + "/user/list_users")
          .then((res) => {
            if (res.status == 200) {
              this.items = res.data["users"];
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    deleteUser(name: string) {
      const BASE_URL = process.env.VUE_APP_BASEURL;
      if (confirm('Delete user "' + name + '"?')) {
        this.axios
            .get(BASE_URL + "/user/delete/" + name)
            .then((res) => {
              if (res.status == 200) {
                this.changeUserStatus = "Success";
                this.changeUserMsg = 'User "' + name + '" deleted';
                this.getUsers();
              }
            })
            .catch((error) => {
              this.changeUserStatus = "Error";
              if (error.response.status == 404) {
                // "Unauthorized. Login first."
                this.$router.push("/login");
              } else if (error.response.status == 500) {
                this.changeUserMsg = "User could not be deleted";
              } else {
                this.changeUserMsg = "Unknown error";
              }
            });
      }
    },
    resetPwd(name: string) {
      const BASE_URL = process.env.VUE_APP_BASEURL;
      if (confirm('Reset password for "' + name + '"?')) {
        this.axios
            .get(BASE_URL + "/user/resetpwd/" + name)
            .then((res) => {
              if (res.status == 200) {
                this.changeUserStatus = "Success";
                this.changeUserMsg =
                    'Password for "' +
                    name +
                    '" reset to "' +
                    res.data["new_pwd"] +
                    '".\nNotify the user to change the password on the next login.';
              }
            })
            .catch((error) => {
              this.changeUserStatus = "Error";
              if (error.response.status == 404) {
                // "Unauthorized. Login first."
                this.$router.push("/login");
              } else if (error.response.status == 500) {
                this.changeUserMsg = "Password could not be reset";
              } else {
                this.changeUserMsg = "Unknown error";
              }
            });
      }
    },
  },
})
